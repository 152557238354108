import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

// State
import { getIsAllConditionsCheckedSelector } from '../../../state/modules/stock/selectors';
import { removeCartItem, addCartItem } from '../../../state/modules/cart/actions';
import {
  applyStock,
  addReward,
  removeIngredient,
  cancelRemovedIngredients,
} from '../../../state/modules/stock/actions';

// Utils
import { customScrollTo } from '../../../utils/customScrollTo';

// Router
import { paths, getRoute } from '../../../entry/routes';

export default function StockGoods({ stockRole }) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const allConditionsChecked = useSelector(getIsAllConditionsCheckedSelector);
  const isApplied = useSelector(state => state.stock.isApplied);
  const data = useSelector(state => state.stock.data);
  const stockGoodsRef = useRef({});

  const [isAdded, setIsAdded] = useState(false);

  const { code = '', details = {} } = data;
  const { conditions = [], rewards = [] } = details;

  const { stockCode, conditionIdx, rewardIdx } = params;
  // rewardIdx === undefined, Number(rewardIdx) === NaN, rewards[Number(rewardIdx)] === undefined
  // так что ошибки тут не будет
  const currentCondition = conditions[Number(conditionIdx)] || {};
  const currentReward = rewards[Number(rewardIdx)] || {};

  const isConditions = stockRole === 'conditions';
  const type = isConditions ? 'good' : 'gift';
  const goods = isConditions ? currentCondition.goods || [] : currentReward.goods || [];
  const title = isConditions ? currentCondition.criteria || '' : currentReward.message || '';
  const productIndex = isConditions ? conditionIdx : rewardIdx;

  // не выводим товары, которые есть в стоп листе
  const filtredGoods = goods.filter(
    good => good.variations.filter(variation => !variation.is_in_stop_list).length > 0,
  );

  const removeIngredientAction = ({ id, variation, ingredientId }) => {
    return dispatch(removeIngredient(id, variation.id, productIndex, ingredientId, stockRole));
  };

  const cancelRemovedIngredientsAction = ({ id, variation }) => {
    return dispatch(cancelRemovedIngredients(id, variation.id, productIndex, stockRole));
  };

  const addToCart = async variation => {
    const { replace, item } = qs.parse(location.search);

    if (isConditions) {
      if (replace && item) {
        dispatch(removeCartItem(item));
      }

      await dispatch(addCartItem({ composition: [{ type: 'good', item: variation }] }));
      setIsAdded(true);
      return;
    }

    const ingredients = variation.include_ingredients || [];

    const filtredIngredients =
      ingredients
        .filter(ingredient => ingredient.removed)
        .map(ingredient => ({ id: ingredient.id, count: 0 })) || [];

    const composition = [{ id: variation.id, ingredients: filtredIngredients }];
    await dispatch(addReward(rewardIdx, composition, replace !== undefined));
    setIsAdded(true);
  };

  useEffect(() => {
    customScrollTo(stockGoodsRef.current, { block: 'start' });

    // если в стейте применен один промокод, а в урле другой, то применяем из урла
    if (stockCode !== code) {
      dispatch(applyStock({ stock_code: stockCode }));
    }

    return () => {
      customScrollTo(stockGoodsRef.current, { block: 'start' });
    };
  }, []);

  // если все условия выполнены (те мы все добавили)
  useEffect(() => {
    if (isAdded && (isConditions ? currentCondition.check : currentReward.applied)) {
      history.goBack();
    }
  }, [isAdded, currentCondition.check, currentReward.applied]);

  // если промокод не применен, то мы не должны находиться на этой странице
  useEffect(() => {
    if (!isApplied) {
      history.push(getRoute(paths.home));
    }
  }, [isApplied]);

  // если это страница с призами за промокод, но условия не выполнены, то мы не должны находиться на этой странице
  useEffect(() => {
    if (stockRole === 'rewards' && !allConditionsChecked) {
      history.push(getRoute(paths.home));
    }
  }, [stockRole, allConditionsChecked]);

  console.log({stockRole})

  return {
    stockGoodsRef,
    title,
    filtredGoods,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    type,
  };
}
