import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// State
import { fetchOrderListIsNeeded } from '../../../state/modules/order/actions';
import { isOrderDoneSelector } from '../../../state/modules/order/selectors';
import orderApi from '../../../state/modules/order/api';

// Utils
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';
import { getUnauthorizedToken } from '../../../utils/auth';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Icons
import TimerGreen from '../../../icons/timer-green.svg';
import Close from '../../../icons/close.svg';

// Styles
import styles from './ThanksForOrderingLink.styl';

const cx = classNames.bind(styles);

const getOrderStatusMessage = status => {
  switch (status) {
    case 0:
    case 1:
      return 'orderStatus.status.taken';
    case 2:
      return 'orderStatus.status.prepared';
    case 3:
      return 'orderStatus.status.ready';
    case 4:
      return 'orderStatus.status.inWay';
    default:
      return 'orderStatus.status.taken';
  }
};

export default function ThanksForOrderingLink(props) {
  const { className } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const timeLeftIntervalRef = useRef(null);
  const intervalRef = useRef(null);

  const lastOrderAuthId = useSelector(state => state.order.list[0]?.id);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isHomeCategoriesStick = useSelector(state => state.ui.isHomeCategoriesStick);

  const [orderData, setOrderData] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isOpen, setIsOpen] = useState(() => {
    if (!isAuthenticated) {
      return true;
    }

    const thx_link_closed = isomorphicCookies().get('thx_link_closed');

    return thx_link_closed !== '1';
  });

  const isOrderDone = isOrderDoneSelector(orderData);

  const lastOrderId = useMemo(() => {
    if (isAuthenticated) {
      return lastOrderAuthId || null;
    }

    return getUnauthorizedToken() ? isomorphicCookies().get('last_order_id') : null;
  }, [lastOrderAuthId, isAuthenticated]);

  const currentDeliveryTime = useMemo(() => {
    if (!timeLeft) {
      return null;
    }

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const secondsWithZero = seconds > 9 ? seconds : `0${seconds}`;

    return `${minutes}:${secondsWithZero}`;
  }, [timeLeft]);

  const handleClick = () => {
    history.push(getRoute(paths.orderTrackingAlias, lastOrderId));
  };

  const handleClose = () => {
    isomorphicCookies().set('thx_link_closed', '1', { expires: 1 });
    setIsOpen(false);
  };

  useEffect(() => {
    setOrderData(null);
    setTimeLeft(null);
  }, [isAuthenticated]);

  // подтягиваем историю заказов, если авторизовались и ее еще нет
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrderListIsNeeded());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isOrderDone && lastOrderId) {
      const getOrderStatus = () => {
        orderApi.getOrderStatus({ action: 'status', order_id: lastOrderId }).then(data => {
          setOrderData({ ...data });
        });
      };

      if (!orderData) {
        getOrderStatus();
      }

      intervalRef.current = setInterval(() => {
        getOrderStatus();
      }, 60000);
    }

    return () => {
      if (!isOrderDone && lastOrderId) {
        clearInterval(intervalRef.current);
      }
    };
  }, [orderData?.order_status, lastOrderId]);

  // если к нам пришло новое оставшее время, отличное от того, что у нас уже записано
  useEffect(() => {
    setTimeLeft(orderData?.time_left);

    if (orderData?.time_left) {
      timeLeftIntervalRef.current = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    }

    return () => {
      if (orderData?.time_left) {
        clearInterval(timeLeftIntervalRef.current);
      }
    };
  }, [orderData?.time_left]);

  // если время закончилось, то очищаем память
  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(timeLeftIntervalRef.current);
    }
  }, [timeLeft]);

  const isVisible =
    isOpen &&
    orderData &&
    !isOrderDone &&
    !isHomeCategoriesStick &&
    !orderData?.client_number.includes('N');

  return (
    <Transition in={isVisible} timeout={{ enter: 0, exit: 175 }} unmountOnExit mountOnEnter>
      {state => (
        <div className={cx('ThanksForOrderingLink', className, `ThanksForOrderingLink_${state}`)}>
          <button
            className={cx('ThanksForOrderingLink__link', className)}
            onClick={handleClick}
            onKeyDown={e => e.key === 'Enter' && handleClick()}
            type="button"
          >
            <TimerGreen className={cx('ThanksForOrderingLink__link-icon')} />
            <span className={cx('ThanksForOrderingLink__link-description')}>
              {orderData?.order_status !== undefined
                ? intl.formatMessage({ id: getOrderStatusMessage(Number(orderData.order_status)) })
                : ''}
              {currentDeliveryTime ? ` | ${currentDeliveryTime}` : ''}
            </span>
          </button>
          <button
            className={cx('ThanksForOrderingLink__cancel')}
            onClick={handleClose}
            onKeyDown={e => e.key === 'Enter' && handleClose()}
            type="button"
          >
            <Close className={cx('ThanksForOrderingLink__cancel-icon')} />
          </button>
        </div>
      )}
    </Transition>
  );
}

ThanksForOrderingLink.defaultProps = {
  className: '',
};

ThanksForOrderingLink.propTypes = {
  className: PropTypes.string,
};
