import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// State
import { fetchBannersIfNeeded } from '../../state/modules/banner/actions';
import { toggleHomeCategories } from '../../state/modules/ui/actions';

export default function useHome(topRef) {
  const dispatch = useDispatch();
  const waypointRef = useRef(false);

  // при выборе категории каталог затухает и появляется
  const isHomeCatalogVisible = useSelector(state => state.ui.isHomeCatalogVisible);
  const categories = useSelector(state => state.catalog.products.categories);

  useEffect(() => {
    dispatch(fetchBannersIfNeeded());
  }, []);

  useEffect(() => {
    // при пересечении topRef выводить хэдер с категориями
    const handleScroll = () => {
      if (topRef.current) {
        const offsetTop = topRef.current.getBoundingClientRect().top;

        if (offsetTop < 0 && !waypointRef.current) {
          waypointRef.current = true;
          return dispatch(toggleHomeCategories(true));
        }

        if (offsetTop >= 0 && waypointRef.current) {
          waypointRef.current = false;
          return dispatch(toggleHomeCategories(false));
        }
      }

      return false;
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      waypointRef.current = false;
      dispatch(toggleHomeCategories(false));
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isHomeCatalogVisible, categories };
}
