import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// State
import { toggleHomeCatalog } from '../../state/modules/ui/actions';
import { customScrollTo } from '../../utils/customScrollTo';
import { sendEventViewProduct } from '../../utils/metrics';
import { getRoute, paths } from '../../entry/routes';

const CategoryNavItem = (props) => {
  const { className, alias, children, style, id } = props;

  const dispatch = useDispatch();
  const elementRef = useRef(null);

  const handleScroll = () => {
    sendEventViewProduct();
    elementRef.current = document.querySelector(`#${alias}`);
    window.history.pushState({}, '', `${getRoute(paths.home)}#${alias}`);
    if (!elementRef.current) {
      return;
    }

    dispatch(toggleHomeCatalog(false));

    setTimeout(() => {
      customScrollTo(elementRef.current, { block: 'start' });
      dispatch(toggleHomeCatalog(true));
    }, 300);
  };

  return (
    <span
      role="menuitem"
      tabIndex={0}
      onClick={handleScroll}
      className={className}
      onKeyPress={e => e.key === 'Enter' && handleScroll()}
      style={style}
      id={id}
    >
      {children}
    </span>
  );
};

export default CategoryNavItem;

CategoryNavItem.defaultProps = {
  className: '',
  style: undefined,
};

CategoryNavItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alias: PropTypes.string.isRequired,
  style: PropTypes.object,
};
