import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

// Assets
import pic from '../assets/okkoClick.png';

// Styles
import styles from './OkkoLink.styl';
import { getRoute, paths } from '../../../entry/routes';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);
export const OkkoLink = () => {
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  return (
    <Link to={getRoute(paths.okko)}>
      <img src={pic}
           alt={'banner'} id={'Okko'} className={cx('OkkoLink', {OkkoLink_mobile: isRealPhone})}
      />
    </Link>
  );
};
