import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// Components
import Heading from '../../../components/Heading';
import AppLink from '../../../components/AppLink';
import Collapse from '../../../components/Collapse';

// Styles
import styles from './SEO.styl';

const cx = classNames.bind(styles);

export default function SEO() {
  const intl = useIntl();
  const { title, content } = useSelector(state => state.city.userCity);
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = e => {
    e.preventDefault();
    setIsOpen(prev => !prev);
  };

  return (
    <div className={cx('SEO', { SEO_opened: isOpen })}>
      <Heading className={cx('SEO__title')} level={3} tagName="h1">
        {title}
      </Heading>
      <div className={cx('SEO__content')}>
        <Collapse height={100} isOpen={isOpen}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Collapse>
      </div>
      <AppLink className={cx('SEO__more')} href="#" onClick={onToggle}>
        {intl.formatMessage({ id: isOpen ? 'seo.text.rollUp' : 'seo.text.more' })}
      </AppLink>
    </div>
  );
}
