import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames/bind';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
// Selectors

// Utils
// Actions
// Icons
// Assets
// Styles
import styles from './HatikoBanner.styl';
import {getRoute, paths} from "../../../entry/routes";
import {toggleHomeCatalog} from "../../../state/modules/ui/actions";
import {customScrollTo} from "../../../utils/customScrollTo";
import {useHatiko} from "../useHatiko";
import CrossIcon from "../../../icons/cross-grey.svg";

const cx = classNames.bind(styles);

const HatikoBanner = () => {
  const {shouldMainHatikoBannerShow} = useHatiko();
  const dispatch = useDispatch()
  const intl = useIntl();

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let timeout;

    if (shouldMainHatikoBannerShow) {
      timeout = setTimeout(() => setVisible(true), 120000);
    }
    return () => clearTimeout(timeout);
  }, []);

  const elementRef = useRef(null);

  const handleScroll = () => {
    elementRef.current = document.querySelector(`#combo`);
    window.history.pushState({}, '', getRoute(paths.category, `combo`));
    if (!elementRef.current) {
      return;
    }

    dispatch(toggleHomeCatalog(false));

    setTimeout(() => {
      customScrollTo(elementRef.current, {block: 'start'});
      dispatch(toggleHomeCatalog(true));
    }, 300);

    setVisible(false)
  };

  const closeHandler = (e) => {
    e.stopPropagation()
    setVisible(false)
  }

  return (
    <>
      <div className={cx('HatikoBanner', (!isVisible || !shouldMainHatikoBannerShow) && 'HatikoBanner_hidden')}
           onClick={handleScroll}>
        <div className={cx('HatikoBanner__image')}/>
        <div className={cx('HatikoBanner__quote')}>
          <p className={cx('HatikoBanner__quote-phrase')}>
            {intl.formatMessage({id: 'promo.hatiko'})}
          </p>
        </div>
        <div className={cx('HatikoBanner__close')} onClick={closeHandler}>
          <CrossIcon width={12} height={12}/>
        </div>
      </div>

      <div className={cx('HatikoBanner__mobile', {'HatikoBanner__mobile_hidden': isVisible})} onClick={handleScroll}/>
    </>

  );
};

export default HatikoBanner;
