import React, {useMemo} from 'react';
import {Link, useLocation, matchPath} from 'react-router-dom';
import classNames from 'classnames/bind';
import {useSelector} from 'react-redux';

// Assets
import pic from '../assets/banner.png';

// Styles
import styles from './GenderLink.styl';
import {getRoute, paths} from "../../../entry/routes";

const cx = classNames.bind(styles);
export const GenderLink = () => {
  const {pathname} = useLocation();
  const _supertag = useSelector(state => state.city.userCity._supertag);

  const shouldBeHidden = useMemo(() => {
    return !!matchPath(pathname, {
      path: `/${_supertag}${paths.promoGender}`,
      exact: true,
      strict: false,
    });
  }, [pathname, _supertag, paths]);

  return (
    <Link to={getRoute(paths.promoGender)}>
      <img src={pic}
        alt={'banner'} id={'HyperPCButton'} className={cx('GenderLink', { GenderLink_hidden: shouldBeHidden })}
      />
    </Link>
  );
};
