import React from "react";

const Paw = <svg height="12" viewBox="0 0 8 7" fill="none" style={{transform: 'translate(1px,1px)'}}
                 xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.99378 6.83946L3.59413 6.7108C2.96714 6.49176 2.32265 6.32645 1.66763 6.21665C1.44378 6.17997 1.23076 6.09445 1.04368 5.96616C0.884386 5.86937 0.754043 5.73153 0.666306 5.56707C0.578568 5.40261 0.536664 5.21759 0.544981 5.03138C0.552951 4.59757 0.82849 4.1057 1.342 3.9816C1.8555 3.85749 2.26539 3.56146 2.64227 3.20849C2.77449 3.07061 2.92832 2.95524 3.0977 2.86691C3.2278 2.80123 3.37488 2.77693 3.51919 2.79728C3.6635 2.81763 3.79812 2.88167 3.90497 2.98077C4.04045 3.11686 4.14799 3.27816 4.22149 3.45557C4.32055 3.69809 4.41961 3.94175 4.52094 4.18427C4.67509 4.52332 4.866 4.84443 5.09024 5.14182C5.2651 5.38869 5.34587 5.68998 5.31796 5.99121C5.30317 6.20321 5.21472 6.40336 5.06792 6.55702C4.92112 6.71068 4.72521 6.80818 4.51411 6.83263C4.4338 6.8444 4.35276 6.85049 4.27159 6.85085C4.18848 6.85199 4.10764 6.84516 3.99378 6.83946Z"
    fill="currentColor"/>
  <path
    d="M3.67869 1.336C3.72554 0.666054 3.37043 0.0954633 2.88553 0.0615558C2.40063 0.0276484 1.96956 0.543264 1.92272 1.21322C1.87587 1.88317 2.23098 2.45376 2.71588 2.48766C3.20078 2.52157 3.63184 2.00596 3.67869 1.336Z"
    fill="currentColor"/>
  <path
    d="M0.9417 3.7985C1.38858 3.78469 1.73539 3.27315 1.71632 2.65593C1.69724 2.03872 1.31951 1.54956 0.872629 1.56337C0.425747 1.57718 0.0789385 2.08873 0.0980117 2.70594C0.117085 3.32316 0.494817 3.81231 0.9417 3.7985Z"
    fill="currentColor"/>
  <path
    d="M5.90051 2.21521C6.19523 1.61174 6.08007 0.949614 5.64329 0.7363C5.20651 0.522987 4.61352 0.839266 4.3188 1.44273C4.02407 2.0462 4.13923 2.70833 4.57601 2.92164C5.01279 3.13495 5.60578 2.81867 5.90051 2.21521Z"
    fill="currentColor"/>
  <path
    d="M6.73656 4.4179C7.06113 3.89257 7.01592 3.2762 6.63556 3.0412C6.25521 2.8062 5.68375 3.04155 5.35918 3.56688C5.0346 4.09221 5.07982 4.70857 5.46017 4.94358C5.84053 5.17858 6.41199 4.94323 6.73656 4.4179Z"
    fill="currentColor"/>
</svg>

export const HaticoCategoryItem = ({word}) => {

  const wordArray = [word[0], word.slice(2)]
  return (
    <span>
        {wordArray.map((letter, idx) => {
          return <>
            {letter}
            {idx !== wordArray.length - 1 && Paw}
          </>
        })}

      </span>
  )
}
