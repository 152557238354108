import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// State
import { addFreeSauce, hideFreeSaucesPanel } from '../../../state/modules/freeSauces/actions';
import { avaivableCountSelector } from '../../../state/modules/freeSauces/selectors';
import { updateCartItem } from '../../../state/modules/cart/actions';

export default function useFreeSaucesPanel() {
  const dispatch = useDispatch();
  const avaivableCount = useSelector(avaivableCountSelector);
  const goodId = useSelector(state => state.freeSauces.goodId);
  const composition = useSelector(state => state.cart.composition);
  const [isAdding, setIsAdding] = useState(null);

  const onAdd = useCallback(
    async variationId => {
      const currentGood = composition.find(good => good.item.id === goodId) || {};
      const includedSauces = currentGood.item?.added_sauces?.map(sauce => sauce.id) || [];

      const onSuccessCallback = () => {
        dispatch(addFreeSauce());
      };

      setIsAdding(variationId);
      await dispatch(
        updateCartItem(
          { ...currentGood, sauces: [variationId, ...includedSauces] },
          onSuccessCallback,
        ),
      );
      setIsAdding(null);
    },
    [composition, goodId],
  );

  useEffect(() => {
    if (avaivableCount === 0) {
      dispatch(hideFreeSaucesPanel());
    }
  }, [avaivableCount]);

  return { onAdd, isAdding };
}
